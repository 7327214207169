<template>
  <div class="home-ele">
    <div class="top">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.Bus_introduction.four }}</span>
        <span class="line"></span>
      </div>
      <div class="detail">
        <div class="itemlist">
          <div class="icon_box">
            <img src="@/assets/images/btc_icon.png" />
            <div class="text">BTC-Bitcoin</div>
          </div>
          <div class="item">
            <div class="item_con left">
              <p>
                <span class="label">{{ objData.item0.value?.title }}</span>
                <span class="con">{{ objData.item0.value?.value }}</span>
              </p>
              <p>
                <span class="label">{{ objData.item1.value?.title }}</span>
                <span class="con">{{ objData.item1.value?.value }}</span>
              </p>
              <p>
                <span class="label">{{ objData.item2.value?.title }}</span>
                <span class="con">{{ objData.item2.value?.value }}</span>
              </p>
            </div>
            <div class="item_con ">
              <p>
                <span class="label">{{ objData.item3.value?.title }}</span>
                <span class="con">{{ objData.item3.value?.value }}</span>
              </p>
              <p>
                <span class="label"> {{ objData.item4.value?.title }}</span>
                <span class="con">{{ objData.item4.value?.value }}</span>
              </p>
              <p>
                <span class="label">{{ objData.item5.value?.title }}</span>
                <span class="con">{{ objData.item5.value?.value }}</span>
              </p>
            </div>
          </div>
          <div class="icon_box flex">
            <span style="color: #27D9B1;font-size: 20px; margin-right: 0px;">{{
              objData.item14.value?.value
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="po_piv"></div>
      <div class="detail">
        <div class="echarts_box">
          <div class="item">
            <div class="left">
              <p class="e_title">{{ objData.item6.value?.title }}</p>
              <p class="e_s_title">{{ objData.item6.value?.value }}</p>
              <p class="e_title">{{ objData.item7.value?.title }}</p>
              <p class="e_s_title">{{ objData.item7.value?.value }}</p>
              <p class="e_title">{{ objData.item8.value?.title }}</p>
              <p class="e_s_title">{{ objData.item8.value?.value }}</p>
            </div>
            <div class="right">
              <div
                class="chart"
                id="main1"
                :style="{ width: '133px', height: '167px' }"
              ></div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <p class="e_title" style="margin-bottom: 30px;">
                {{ objData.item10.value?.title }}
              </p>
              <p class="e_s_title">
                {{ objData.item10.value?.value.split('|')[0] }}
              </p>
              <p class="e_s_title" style="margin-top: 4px;">
                {{ objData.item10.value?.value.split('|')[1] }}
              </p>
            </div>
            <div class="right">
              <div
                class="chart"
                id="main2"
                :style="{ width: '133px', height: '167px' }"
              ></div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <p class="e_title" style="margin-bottom:30px;">
                {{ objData.item12.value?.title }}
              </p>
              <p class="e_s_title">
                {{ objData.item12.value?.value }}
              </p>
              <p class="e_s_title" style="margin-top: 4px;">
                {{ objData.item13.value?.value }}
              </p>
            </div>
            <div class="right">
              <div
                class="chart"
                id="main3"
                :style="{ width: '133px', height: '167px' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { returnLanguage } from '../../../assets/js/language'
import { getSl } from '@/api/new'
import * as echarts from 'echarts'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
])
export default {
  name: 'AppHeaderNav',
  props: ['isto'],
  setup(props) {
    let isJump = ref(props.isto)
    const store = useStore()
    const router = useRouter()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)

    const langvalue = ref(returnLanguage(langtype))

    const goInfo = () => {
      // if (isJump.value) {
      //   router.push('/slinfo')
      // }
    }
    let objData = {
      item0: ref(),
      item1: ref(),
      item2: ref(),
      item3: ref(),
      item4: ref(),
      item5: ref(),
      item6: ref(),
      item7: ref(),
      item8: ref(),
      item9: ref(),
      item10: ref(),
      item11: ref(),
      item12: ref(),
      item13: ref(),
      item14: ref(),
      item15: ref(),
      item16: ref(),
    }

    const getList = async () => {
      let { data } = await getSl()
      console.log(data)
      data.list.forEach((e, index) => {
        objData[`item${index}`].value = e
      })

      createChart()
      createChartTwo()
      createChartone()
    }

    const createChart = () => {
      let chartDom = document.getElementById('main1')
      let myChart = echarts.init(chartDom)
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ['#DFE9FDD6', '#018DFF'], //修改图表颜色
        //graphic控制环形中间出现字，其中有两个对象，就是两行字
        graphic: [
          {
            type: 'text', //控制内容为文本文字
            left: 'center',
            top: '59px', //调整距离盒子高处的位置
            style: {
              fill: '#34495E', //控制字体颜色
              text: `${objData.item9?.value?.title}`, //控制第一行字显示内容
              fontSize: '20px'
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '90px',
            z: 10,
            style: {
              text: `${objData.item9?.value?.value}`,
              font: 'PingFang SC',
              fontSize: '16px',
              lineHeight: 15,
              fill: '#5D5D84'
            }
          }
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '90%'], //在此处控制环的大小，第一个数据为内环，第二个为外环
            avoidLabelOverlap: false,

            //在这就是控制提示线文字的代码
            label: {
              normal: {
                show: true, //开启提示线文字
                position: 'outer', //设置文字的所在的位置
                formatter: '{c}亿', //设置展示的内容
                borderWidth: 20, //大小设置
                borderRadius: 4,
                color: '#F3DF04', //文字颜色
                rich: {
                  //设置字体，c代表数据内容，b代表数据名称（这两个都可以在option中的data里面看到）
                  c: {
                    fontSize: 16,
                    lineHeight: 12
                  }
                }
              }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            //设置提示线
            labelLine: {
              normal: {
                show: false, //开启提示线展示
                length: 30, //设置第一条提示线长度
                length2: 30, //设置第二条提示线长度
                lineStyle: {
                  color: 'black'
                }
              }
            },
            data: [
              { value: 1400244, name: '剩余带挖' },
              { value: 19599756, name: '流通数量' }
            ]
          }
        ]
      }

      option && myChart.setOption(option)
    }
    const createChartTwo = () => {
      let chartDom = document.getElementById('main3')
      let myChart = echarts.init(chartDom)
      let num = objData.item13?.value?.value.split(' ')[1]
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ['#DFE9FDD6', '#018DFF'], //修改图表颜色
        //graphic控制环形中间出现字，其中有两个对象，就是两行字
        graphic: [
          {
            type: 'text', //控制内容为文本文字
            left: 'center',
            top: '59px', //调整距离盒子高处的位置
            style: {
              fill: '#34495E', //控制字体颜色
              text: `${objData.item13?.value?.title}`, //控制第一行字显示内容
              fontSize: '20px'
            }
          }
        ],
        series: [
          {
            name: '全球市值',
            type: 'pie',
            radius: ['60%', '90%'], //在此处控制环的大小，第一个数据为内环，第二个为外环
            avoidLabelOverlap: false,

            //在这就是控制提示线文字的代码
            label: {
              normal: {
                show: true, //开启提示线文字
                position: 'outer', //设置文字的所在的位置
                formatter: '{c}', //设置展示的内容
                borderWidth: 20, //大小设置
                borderRadius: 4,
                color: '#F3DF04', //文字颜色
                rich: {
                  //设置字体，c代表数据内容，b代表数据名称（这两个都可以在option中的data里面看到）
                  c: {
                    fontSize: 16,
                    lineHeight: 12
                  }
                }
              }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            //设置提示线
            labelLine: {
              normal: {
                show: false, //开启提示线展示
                length: 30, //设置第一条提示线长度
                length2: 30, //设置第二条提示线长度
                lineStyle: {
                  color: 'black'
                }
              }
            },
            data: [{ value: num.split(',').join('') }]
          }
        ]
      }

      option && myChart.setOption(option)
    }
    const createChartone = () => {
      let num = objData.item10.value?.value.split('|')[0].split(' ')[1]
      let num2 = objData.item10.value?.value.split('|')[1].split(' ')[1]
      let chartDom = document.getElementById('main2')
      let myChart = echarts.init(chartDom)
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ['#DFE9FDD6', '#018DFF'], //修改图表颜色
        //graphic控制环形中间出现字，其中有两个对象，就是两行字
        graphic: [
          {
            type: 'text', //控制内容为文本文字
            left: 'center',
            top: '59px', //调整距离盒子高处的位置
            style: {
              fill: '#34495E', //控制字体颜色
              text: `${objData.item11?.value?.value}`, //控制第一行字显示内容
              fontSize: '20px'
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '90px',
            z: 10,
            style: {
              text: `${objData.item11?.value?.title}`,
              font: 'PingFang SC',
              fontSize: '16px',
              lineHeight: 15,
              fill: '#5D5D84'
            }
          }
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '90%'], //在此处控制环的大小，第一个数据为内环，第二个为外环
            avoidLabelOverlap: false,

            //在这就是控制提示线文字的代码
            label: {
              normal: {
                show: true, //开启提示线文字
                position: 'outer', //设置文字的所在的位置
                formatter: '{c}亿', //设置展示的内容
                borderWidth: 20, //大小设置
                borderRadius: 4,
                color: '#F3DF04', //文字颜色
                rich: {
                  //设置字体，c代表数据内容，b代表数据名称（这两个都可以在option中的data里面看到）
                  c: {
                    fontSize: 16,
                    lineHeight: 12
                  }
                }
              }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            //设置提示线
            labelLine: {
              normal: {
                show: false, //开启提示线展示
                length: 30, //设置第一条提示线长度
                length2: 30, //设置第二条提示线长度
                lineStyle: {
                  color: 'black'
                }
              }
            },
            data: [{ value: num.split(',').join('') }, { value: num2.split(',').join('') }]
          }
        ]
      }

      option && myChart.setOption(option)
    }

    getList()
    onMounted(() => {
    })
    watch(
      () => store.state.home.type,
      (newVal, oldvalu) => {
        store.commit('home/setType', newVal)
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
        getList()
      },
      { immediate: true }
    )
    return { langvalue, type, objData, getList, goInfo }
  }
}
</script>
<style scoped lang="less">
.home-ele {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #eaebf0;
  .top {
    background: linear-gradient(180deg, #0186ff 0%, #01b1ff 100%);
    padding: 123px 0 70px;
  }

  .bottom {
    background: #fff;
    position: relative;
    .po_piv {
      width: 100%;
      position: absolute;
      height: 400px;
      right: 0;
      left: 0;
      top: -20px;
      z-index: 0;
      background: url('../../../assets/images/div.row.png');
      background-size: 100% 100%;
    }
  }
}
.title {
  font-size: 40px;
  font-weight: 400;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}
.stitle {
  color: #ffffff50;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
}
.detail {
  width: 1340px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 300;
  padding: 100px 0 0;
  .itemlist {
    display: flex;
    justify-content: space-between;
    .item {
      margin: 0 20px 0 60px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      .item_con {
        padding: 0 40px;
        flex: 1;
        p {
          display: flex;
          justify-content: space-between;
          .label {
            color: #ebebeb;
            font-family: 'PingFang SC';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
          .con {
            text-align: right;
            color: #fff;
            font-family: 'PingFang SC';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
        p + p {
          margin-top: 26px;
        }
      }
      .item_con + .item_con {
        border-left: 1px solid rgba(211, 213, 224, 0.31);
        padding-right: 0;
      }
    }

    .icon_box {
      width: 144px;
      img {
        width: 86px;
        height: 85px;
      }
      .text {
        margin-top: 20px;
        color: #fff;

        text-align: center;
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 21.6px;
      }
      p.icon {
        display: flex;
        align-items: center;

        .s_title {
          color: #fff;
          text-align: center;
          font-family: 'PingFang SC';
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          vertical-align: middle;
          margin-right: 4px;
        }
        .switch {
          width: 17px;
          height: 17px;
        }
      }
      &.flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 300px;
      }
    }
  }
}
.echarts_box {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  .item {
    padding: 0 40px;
    flex: 1;
    display: flex;
    text-align: left;
    .e_title {
      margin-top: 23px;
      color: #5d5d84;

      font-family: 'PingFang SC';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
    }
    .e_s_title {
      text-align: left;
      font-family: 'PingFang SC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      span {
        color: #8d9ea7;
        font-family: 'PingFang SC';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .right,
    .left {
      flex: 1;
    }
  }
  .item + .item {
    border-left: 1px solid #ccc;
  }
}
</style>
